<template>
  <div class="student-list">
    <!-- 搜索选项 -->
    <el-row class="i-select">
      <ul class="s-contianer">
        <li class="s-item">
          <div class="s-title">性别：</div>
          <div>
            <el-select v-model="selectListVal.gender" size="small" @change="genderStatus" clearable>
              <el-option v-for="(item, index) in genderList" :key="index" :label="item" :value="index">
              </el-option>
            </el-select>
          </div>
        </li>
        <li class="s-item">
          <div class="s-title">身高：</div>
          <div>
            <el-select v-model="selectListVal.height" size="small" clearable @change="heightStatus">
              <el-option v-for="(item, index) in heightList" :key="index" :label="item" :value="index">
              </el-option>
            </el-select>
          </div>
        </li>
        <li class="s-item">
          <div class="s-title">年龄：</div>
          <div>
            <el-select v-model="ageInputVal" size="small" clearable @change="ageStatus">
              <el-option v-for="(item, index) in ageList" :key="index" :label="item"
                :value="{ value: index, label: item }">
              </el-option>
            </el-select>
          </div>
        </li>
        <li class="s-item">
          <div class="s-title">学历：</div>
          <div>
            <el-select v-model="selectListVal.education_level" size="small" clearable @change="educationStatus">
              <el-option v-for="(item, index) in educationList" :key="index" :label="item" :value="index">
              </el-option>
            </el-select>
          </div>
        </li>

      </ul>
      <ul class="s-contianer" style="margin-top: 20px">
        <li class="s-item">
          <div class="s-title">兵役史：</div>
          <div>
            <el-select v-model="selectListVal.army_service_history" size="small" clearable @change="armyHistoryStatus">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </div>
        </li>
        <li class="s-item">
          <div class="s-title">证书：</div>
          <div>
            <el-select v-model="selectListVal.pid" size="small" clearable multiple @change="certStaffStatus">
              <el-option v-for="(item, index) in certNameList" :key="index" :label="item.name" :value="index">
                </el-option>
            </el-select>
          </div>
        </li>
        <!-- <li class="s-item">
          <div class="s-title">消防证：</div>
          <div>
            <el-select v-model="selectListVal.cert_fire" size="small" clearable @change="certFireStatus">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </div>
        </li> -->
      </ul>
      <div class="i-empty">
        <el-button type="text" @click="emptySelectValue()">清空筛选条件</el-button>
      </div>
    </el-row>
    <talent-iteam :selectListVal="selectListVal"></talent-iteam>
  </div>
</template>

<script>
  import talentIteam from './components/talentIteam.vue'
  import {
    selectInitJobApi
  } from '@/api/home'
  export default {
    name: "talentList",
    components: {
      talentIteam
    },
    data() {
      return {
        selectListVal: {
          gender: '',
          height: '',
          education_level: '',
          army_service_history: '',
          minDay: '',
          maxDay: '',
          pid: [],
        },
        certNameList: '',
        ageInputVal: '',
        educationList: {},
        genderList: {
          "0": "女",
          "1": "男",
        },
        heightList: {
          "155": "155cm以上",
          "160": "160cm以上",
          "165": "165cm以上",
          "170": "170cm以上",
          "175": "175cm以上",
          "180": "180cm以上",
        },
        ageList: {
          "0": '不限',
          "1": "16-20岁",
          "2": "21-30岁",
          "3": "31-40岁",
          "4": "41-50岁",
          "5": "51-60岁",
          "6": "60岁以上",
        },
        ageMapList: [{
            id: '0',
            ageVal: []
          },
          {
            id: '1',
            ageVal: [16, 20]
          },
          {
            id: '2',
            ageVal: [21, 30]
          },
          {
            id: '3',
            ageVal: [31, 40]
          },
          {
            id: '4',
            ageVal: [41, 50]
          },
          {
            id: '5',
            ageVal: [51, 60]
          },
          {
            id: '6',
            ageVal: [61, 70]
          }
        ],
      }
    },
    created() {
      this.getSelectInit()
    },
    methods: {
      //下拉数据初始化
      async getSelectInit() {
        const {
          data: res
        } = await selectInitJobApi()
        if (res.status != 10000) {
          return this.$message.error('下拉列表数据初始化失败')
        }
        this.certNameList = res.result.certName
        this.educationList = res.result.education
      },
      genderStatus(val) {
        this.selectListVal.gender = val
      },
      heightStatus(val) {
        this.selectListVal.height = val
      },
      educationStatus(val) {
        this.selectListVal.education_level = val
      },
      armyHistoryStatus(val) {
        this.selectListVal.army_service_history = val
      },
      certStaffStatus(val) {
        this.selectListVal.pid = val
      },
      certFireStatus(val) {
        this.selectListVal.cert_fire = val
      },
      ageStatus(params) {
        const {
          value,
          label
        } = params
        this.ageInputVal = label
        let val = value
        let ageVal = ''
        this.ageMapList.forEach(item => {
          if (item.id == val) {
            ageVal = item.ageVal
          }
        })
        this.selectListVal.minDay = ageVal[0]
        this.selectListVal.maxDay = ageVal[1]
      },
      //清空下拉选择项
      emptySelectValue() {
        this.selectListVal.gender = ''
        this.selectListVal.height = ''
        this.selectListVal.education_level = ''
        this.selectListVal.army_service_history = ''
        this.selectListVal.minDay = ''
        this.selectListVal.maxDay = ''
        this.ageInputVal = ''
        this.selectListVal.pid = ''
      }
    }
  }

</script>

<style lang="less" scoped>
  //   /deep/.el-select {
  //     width: 100%;
  //   }

  /deep/.el-select .el-input__inner:focus {
    border-color: @primaryColor;
  }

  .student-list {
    .i-select {
      padding-bottom: 20px;
      position: relative;

      .s-contianer {
        display: flex;
      }

      .s-item {
        .s-title {
          margin-bottom: 10px;
        }

        /deep/ .el-input__inner {
          color: @primaryColor;
        }

        /deep/.el-select {
          width: 90%;
          border-color: @primaryColor;
        }

        /deep/.el-input {
          border-color: @primaryColor;
        }
      }
    }

    .i-empty {
      position: absolute;
      bottom: 10px;
      right: 15%;
      text-align: right;

      .el-button {
        color: gray;
        font-size: 15px;
      }
    }
  }

</style>
