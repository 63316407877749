<template>
  <div class="talent-item" v-loading="loading">
    <el-card>
      <!-- show-header 是否显示表头 -->
      <el-table ref="multipleTable" :data="stdInfo" tooltip-effect="dark" :show-header="false" border
        @row-click="goStdDetails">
        <el-table-column min-width="80%">
          <template slot-scope="scope">
            <el-row class="i-list">
              <el-col :span="4" class="l-col">
                <el-image :src="imageSrc(scope.row.user_avatar, scope.row.gender)"></el-image>               
                <div class="Real" v-if="scope.row.user_auth_state == 3">
                  <span>已实名</span>
                </div>
              </el-col>
              <el-col :span="20" class="l-col">
                <div class="i-info">
                  <div class="i-name">{{ scope.row.user_nickname}}</div>
                  <el-row class="i-content">
                    <el-col :span="12" style="width:100%">
                      <ul class="c-info">
                        <li class="i-item">
                          {{ scope.row.gender | genderFormat }}
                        </li>
                        <li class="i-item" v-if="scope.row.id_cart_num">{{ scope.row.id_cart_num }}岁</li>
                        <li class="i-item" v-if="scope.row.height">{{ scope.row.height }}cm</li>
                        <li class="i-item" v-if="scope.row.min_salary_range == 0 || scope.row.min_salary_range == null ">面议</li>
                        <li class="i-item" v-else>{{scope.row.min_salary_range}}-{{scope.row.max_salary_range}}k</li>
                        <li class="i-item" v-if=" scope.row.education_level">{{ scope.row.education_level }}</li>
                        <li class="i-item" v-if="scope.row.job_wanted == 1">正在找工作</li>
                        <li class="i-item" v-if="scope.row.job_wanted == 0">暂不找工作</li>
                        <!-- <li class="i-item" v-if="scope.row.job_wanted == 2">在校生兼职</li> -->
                        <!-- <li class="i-item">身高：{{ scope.row.height }}</li>
                        <li class="i-item">{{ scope.row.education_level }}</li> -->
                      </ul>
                    </el-col>
                    <el-col :span="12">
                      <!-- <div class="i-exp mt5">
                        2021.03-2023.08 十二方研究院*程序员
                      </div> -->
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
            <div class="certName"><span v-for="(item,index) in scope.row.certName" :key="index" class="cert">{{item}}</span></div>
          </template>
        </el-table-column>
        <el-table-column min-width="20%" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.user_phone" class="userPhone">{{scope.row.user_phone}}</div>
            <div v-else>
              <look-phone :userId="scope.row.user_id"></look-phone>
            </div>            
            <div class="c-icon" v-if="collectIds.indexOf(scope.row.user_id) == -1"
              @click.stop="collectStdInfo(scope.row.user_id)">
              <i class="fa fa-star-o"></i>
              <span>收藏</span>
            </div>
            <div class="r-icon" v-if="collectIds.indexOf(scope.row.user_id) != -1">
              <i class="fa fa-star-o"></i>
              <span>已收藏</span>
            </div>
            
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-row class="l-pagination">
        <Pagination :page.sync="page" :limit.sync="limit" :total="count" @getList="getTalentList" />
      </el-row>
      <!-- /分页 -->
    </el-card>
  </div>
</template>

<script>
  import {
    dropDownSearchStudentApi,
    collectStdInfoApi
  } from '@/api/talentPool'
  import Pagination from '@/components/Pagination'
  import lookPhone from '@/components/lookPhone/index.vue'
  export default {
    name: "talentIteam",
    props: ['selectListVal'],
    components: {
      Pagination,
      lookPhone
    },
    data() {
      return {
        stdInfo: [],
        loading: false,
        page: 1,
        limit: 5,
        count: 0,
        dialogVisible: false,
        balanceDialogVisible: false,
        balanceTitle: '',
        user_id: '',
        isShow: true,
        userIds: [],
        collectIds: [],
      }
    },
    filters: {
      genderFormat(val) {
        if (val == 1) {
          return val = '男'
        } else if (val == 2) {
          return val = '未知'
        } else {
          return val = '女'
        }
      }
    },
    created() {
      this.getTalentList()
    },
    watch: {
      selectListVal: {
        handler(val) {
          this.selectListVal = val
          this.page = 1
          this.getTalentList()
        },
        deep: true
      }
    },
    methods: {
      //初始化列表数据
      async getTalentList() {
        this.loading = true
        const {
          data: res
        } = await dropDownSearchStudentApi(this.selectListVal, this.page, this.limit)
        if (res.status != 10000) {
          return this.$message.error('获取学员列表数据失败')
        }
        this.stdInfo = res.result.list
        this.count = res.result.count
        this.loading = false
      },
      //跳转至学员详情页
      goStdDetails(row) {
        this.$router.push(`/pc/talentPool/detail?userId=${row.user_id}`)
      },
      imageSrc(imgUrl, gender) {
        if (imgUrl) {
          return imgUrl
        } else if (imgUrl == null && gender == 0) {
          return require('../../../assets/images/woman.jpg')
        } else if (imgUrl == null && gender == 1) {
          return require('../../../assets/images/man.jpg')
        }
      },
      async collectStdInfo(id) {
        const {
          data: res
        } = await collectStdInfoApi(id)
        if (res.status != 10000) {
          return this.$message.error(res.message)
        }
        this.$message.success('收藏成功')
        this.collectIds.push(id)
      }
    },
  }

</script>

<style lang="less" scoped>
  .balanceColor {
    color: #ff7125;
  }

  .talent-item {
    .i-list {
      display: flex;
      padding: 20px;

      .el-image {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        vertical-align: middle;
        margin: -3px 0 0 10px;
        border: none;
      }

      .i-name {
        font-weight: 600;
      }

      .i-content {
        display: flex;

        .c-info {
          display: flex;
          color: #8d92a1;
          margin-top: 10px;
          font-size: 12px;
          width: 570px;
          .i-item {
            // border-left: 1px solid #8d92a1;
            padding-right: 10px;
            height: 12px;
            line-height: 12px;
          }

          .i-item:last-child {
            padding-right: 0px;
            border-left: none;
          }
        }

        .i-exp {
          margin-bottom: 5px;
          font-size: 13px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .l-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .c-icon {
      margin-top: 10px;

      span {
        margin-left: 5px;
        font-size: 12px;
      }
    }

    .c-icon:hover {
      color: @primaryColor;
    }

    .r-icon {
      margin-top: 10px;

      span {
        margin-left: 5px;
        font-size: 12px;
      }

      color: @primaryColor;
    }

    /deep/ .el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__wrapper {
      top: 25%;
    }

    .l-pagination {
      text-align: right;
      margin-top: 20px;
    }

    .p-text {
      font-size: 16px;
      font-weight: 600;
      color: #ff7125;

      .p-phone {
        margin-left: 5px;
      }
    }

    .d-txt {
      color: #ff7125;
    }

    .certName{
      padding: 0 20px 20px;

      .cert{
        display: inline-block;
        background: #f56c6c;
        color: #fff;
        padding: 5px 8px;
        border-radius: 8px;
        font-size: 12px;
        margin: 0 6px 0 0;
      }
    }

    .userPhone{
      font-size: 16px;
      color: red;
      font-weight: bold;
    }

    .Real span{
      display: inline-block;
      padding: 1px 5px;
      color: #0092ff;
      font-size: 12px;
      line-height: 1.5;
      border: 1px solid #EBEEF5;
      margin: 5px 0 0 8px;
    }
  }

</style>
